import React, { useState } from 'react';
import { links } from '../components/Nav';

const GlobalContext = React.createContext();

export function GlobalProvider({ children }) {
  const [navNumber, setNavNumber] = useState(1);
  const [prevNavNumber, setPrevNavNumber] = useState(1);
  const [direction, setDirection] = useState('left');

  const urlChecker = (url) => {
    links.forEach((link) =>
      url === link.to || url === `${link.to}/`
        ? setNavNumber(parseInt(link.id))
        : null
    );
    console.log(url);
    url.includes('/artist/') ? setNavNumber(parseInt(2)) : null;
    url.includes('/event/') ? setNavNumber(parseInt(3)) : null;
  };

  const checkNavPosition = (navNumber) => {
    const url = typeof window !== 'undefined' ? window.location.pathname : '';
    urlChecker(url);

    setPrevNavNumber(navNumber);
    navNumber > prevNavNumber ? setDirection('right') : setDirection('left');
  };

  return (
    <GlobalContext.Provider
      value={[navNumber, setNavNumber, urlChecker, checkNavPosition, direction]}
    >
      {children}
    </GlobalContext.Provider>
  );
}
export default GlobalContext;
