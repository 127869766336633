import styled from 'styled-components';

export const NavStyles = styled.nav`
  /* mobile & tablet  */
  @media screen and (max-width: 63.9375em) {
    position: fixed;
    z-index: 999;
    width: 40%;
    right: 70px;
    top: 20px;
    text-align: center;

    list-style: none;
    li {
      transform: unset !important;
      padding-top: 4px;
      padding-bottom: 4px;
      a {
        font-family: 'regular';
        color: #000;
        text-decoration: none;
      }
    }
  }

  /* desktop */
  @media screen and (min-width: 64em) {
    position: fixed;
    z-index: 99;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      transform: rotate(0deg);
    }
    li {
      position: fixed;
      top: 0;
      left: 0;
      width: var(--nav-width);
      height: 100vh;
      display: block;

      span {
        height: inherit;
        width: inherit;
        display: block;
      }
      a {
        font-size: 17px;
        display: block;
        height: inherit;
        font-family: 'regular';
        padding-left: var(--nav-width);
        text-decoration: none;
        display: block;
        color: black;
        .text {
          transform: rotate(90deg) translate(35px, 10px);
          transform-origin: top;
        }
      }
    }
  }
`;
