import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import LetterR from './front/LetterR';
import LetterI from './front/LetterI';
import LetterZ from './front/LetterZ';
import LetterO1 from './front/LetterO1';
import LetterO2 from './front/LetterO2';
import LetterM from './front/LetterM';
import LineLarge from './front/LineLarge';
import LineSmall from './front/LineSmall';
import LineMedium from './front/LineMedium';
import LineXSmall from './front/LineXSmall';
import Line from './front/Line';

import rizoom from '../../assets/images/cover-image.svg';
import DesktopCoverSVG from '../DesktopCoverSVG';
import MobileCoverSVG from '../MobileCoverSVG';

const CoverResponsiveFrontStyles = styled.div`
  background: var(--red);
  min-height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  display: flex;
  color: var(--white);
  position: relative;
  z-index: 200;
  overflow: hidden;
  position: fixed;
  img {
    width: 80vw;
  }
  @media screen and (min-width: 64em) {
    display: none;
  }
`;
const CoverFrontStyles = styled.div`
  background: var(--red);
  min-height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  color: var(--white);
  position: relative;
  z-index: 200;
  overflow: hidden;
  position: fixed;
  display: none;
  @media screen and (min-width: 64em) {
    display: flex;
  }

  .line {
    position: absolute;
    width: 85px;
    height: 16px;
    background: #f0eae8;
    transition: 0.8s all ease-in-out;
  }
  .shape {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    object-position: center center;
  }
`;

export default function CoverFront() {
  const [cover, setCover] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      const coverFront = document.querySelector('.coverFront');
      coverFront?.classList.add('is-hidden');
    }, 3800);
    setTimeout(() => {
      setCover(true);
    }, 2000);
  });

  return (
    <div className="coverFront">
      <CoverResponsiveFrontStyles>
        {/* <img src={rizoom} alt="rizoom" /> */}
        <MobileCoverSVG />
      </CoverResponsiveFrontStyles>

      <CoverFrontStyles>
        <DesktopCoverSVG />
      </CoverFrontStyles>
    </div>
  );
}
