// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-actueel-js": () => import("./../../../src/pages/actueel.js" /* webpackChunkName: "component---src-pages-actueel-js" */),
  "component---src-pages-archief-js": () => import("./../../../src/pages/archief.js" /* webpackChunkName: "component---src-pages-archief-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-speellijst-js": () => import("./../../../src/pages/speellijst.js" /* webpackChunkName: "component---src-pages-speellijst-js" */),
  "component---src-templates-artist-js": () => import("./../../../src/templates/Artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/Event.js" /* webpackChunkName: "component---src-templates-event-js" */)
}

