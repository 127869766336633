import React from 'react';
import styled from 'styled-components';
import instagram from '../assets/images/social_instagram.svg';
import facebook from '../assets/images/social_facebook.svg';
import vimeo from '../assets/images/social_vimeo.svg';
import rizoom from '../assets/images/rizoom_footer.svg';
import arrow from '../assets/images/arrow.svg';

const FooterStyles = styled.footer`
  font-size: 14px;
  background: var(--white);
  margin: 0 auto;
  padding: 0px 15px;
  position: relative;
  z-index: 102;

  address {
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      grid-column: span 4;
    }
    grid-column: span 12;

    @media screen and (min-width: 64em) {
      grid-column: span 2;
    }
    display: block;
    font-style: normal;
    b {
      margin-bottom: -5px;
      display: block;
    }
  }

  .newsletter {
    grid-column: span 12;
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      grid-column: span 4;
    }
    @media screen and (min-width: 64em) {
      grid-column: 8 / span 5;
    }
    display: flex;
    flex-wrap: no-wrap;
    flex-direction: column;
  }
  .tag {
    grid-column: span 12;
    order: 2;
    @media screen and (min-width: 64em) {
      grid-column: span 5;
      order: unset;
    }
    margin-top: var(--footer-gap);
    color: #767676;
    display: flex;
    gap: 10px;
    align-items: flex-end;
  }
  a {
    text-decoration: none;
    color: #000;
    font-family: 'regular';
  }
  .logo {
    display: flex;
    grid-column: span 6;
    @media screen and (min-width: 64em) {
      grid-column: 6 / span 3;
    }
    margin-top: var(--footer-gap);
    text-align: center;
  }
  .socials {
    grid-column: span 6;
    @media screen and (min-width: 64em) {
      grid-column: 10 / span 3;
    }
    margin-top: var(--footer-gap);
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    a {
      margin-left: 10px;
    }
  }
  b {
    font-family: 'medium';
  }
  input {
    outline: none;
    display: inline-block;
    width: 100%;
  }
  .subscribe-form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .submit-btn {
    display: inline-block;
    outline: none;
    width: 30px;
    background-image: url(${arrow});
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: right;
    height: 30px;
    transform: translate(-5px, -34px);
  }
`;

export default function Footer(props) {
  return (
    <FooterStyles>
      <div className="footer-block">
        {props?.footerData?.edges.map((data, i) =>
          i === 1 ? (
            <React.Fragment key={i}>
              <address>
                <p>
                  <b>{data.node.title_1}</b>
                  <br />
                  {data.node.address_1 ? (
                    <>
                      {data.node.address_1}
                      <br />
                    </>
                  ) : null}
                  {data.node.address_2 ? (
                    <>
                      {data.node.address_2}
                      <br />
                    </>
                  ) : null}
                  {data.node.address_3 ? (
                    <>
                      {data.node.address_3}
                      <br />
                    </>
                  ) : null}
                </p>
              </address>
              <address>
                <p>
                  {data.node.title_2 ? (
                    <>
                      <b>{data.node.title_2}</b>
                    </>
                  ) : null}
                  <br />
                  {data.node.phone ? (
                    <>
                      {' '}
                      <a href={`tel:${data.node.phone}`}>{data.node.phone}</a>
                      <br />
                    </>
                  ) : null}
                  <a href={`mailto:${data.node.email}`}>{data.node.email}</a>
                </p>
              </address>
            </React.Fragment>
          ) : null
        )}

        <div className="newsletter">
          <p>
            <b>Schrijf je in voor de maandelijkse nieuwsletter</b>
          </p>
          

                      
              <div id="mc_embed_signup">
              <form action="https://rizoom.us14.list-manage.com/subscribe/post?u=cf003d913395a6808b43df62b&amp;id=88dfbfe582" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">
                  <div id="mc_embed_signup_scroll">
              <div className="mc-field-group">
                <input type="email" placeholder="Je emailadres" name="EMAIL" className="required email" id="mce-EMAIL" />
                <input type="submit" value="Inschrijven" name="subscribe" id="mc-embedded-subscribe" className="button"/>
              </div>
                <div id="mce-responses" className="clear foot">
                  <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
                  <div className="response" id="mce-success-response" style={{ display:'none' }}></div>
                </div>  

                </div>
              </form>
              </div>
              <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script>
          
        </div>
        <div className="tag">
          Door&nbsp;<a href="https://www.okaia.nl" target="_blank">Okaia.nl</a> 
          <a href="https://www.suzeswarte.nl/" target="_blank">Suze Swarte</a>
          <a href="https://jordnoorbeek.com/" target="_blank">Studio Jord Noorbeek</a>
        </div>
        <div className="logo">
          <img src={rizoom} width="172" alt="Vimeo" />
        </div>
        {props?.footerData?.edges.map((data, i) =>
          i === 1 ? (
            <React.Fragment key={i}>
              <div className="socials">
                {data.node.vimeo_url && (
                  <a href={data.node.vimeo_url} target="_blank">
                    <img
                      src={vimeo}
                      width="24"
                      className="social"
                      height="21"
                      alt="Vimeo"
                    />
                  </a>
                )}
                {data.node.instagram_url && (
                  <a href={data.node.instagram_url} target="_blank">
                    <img
                      src={instagram}
                      width="24"
                      className="social"
                      height="21"
                      alt="Instagram"
                    />
                  </a>
                )}
                {data.node.facebook_url && (
                  <a href={data.node.facebook_url} target="_blank">
                    <img
                      src={facebook}
                      width="24"
                      className="social"
                      height="21"
                      alt="Facebook"
                    />
                  </a>
                )}
              </div>
            </React.Fragment>
          ) : null
        )}
      </div>
    </FooterStyles>
  );
}
