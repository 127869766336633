import { createGlobalStyle } from 'styled-components';

import regular from '../assets/fonts/maax-webfont.woff';
import medium from '../assets/fonts/maax-medium-webfont.woff';
import bold from '../assets/fonts/maax-bold-webfont.woff';

const Typography = createGlobalStyle`
@font-face {
  font-family: 'regular';
  src: url(${regular});
}
@font-face {
  font-family: 'medium';
  src: url(${medium});
}

@font-face {
  font-family: 'bold';
  src: url(${bold});
}
  html {
    font-family: 'regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--black);
  }
  p {
      font-family: 'regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: 'medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: normal;
  }
  b {
    font-family: 'bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 300;
}
`;

export default Typography;
