import React, { useState, useEffect, useContext } from 'react';
import { Link, navigate, graphql } from 'gatsby';

import { motion } from 'framer-motion';

import GlobalContext from '../context/GlobalContextProvider';
import { NavStyles } from '../styles/NavStyles';

export const links = [
  {
    id: '1',
    name: 'Rizoom',
    to: '/',
    background: 'beige',
  },
  {
    id: '2',
    name: 'Actueel',
    to: '/actueel',
    background: 'white',
  },
  {
    id: '3',
    name: 'Speellijst',
    to: '/speellijst',
    background: 'lightbeige',
  },
  {
    id: '4',
    name: 'Archief',
    to: '/archief',
    background: 'beige',
  },
  {
    id: '5',
    name: 'Contact',
    to: '/contact',
    background: 'red',
  },
];

const variants = {
  hidden: {
    x: '-500px',
  },
  visible: (i) => ({
    x: 0,
    fontSize: '19px',
    transition: {
      delay: i * 0.005,
    },
  }),

  left: {
    x: '0px',
  },
  right: {
    x: 'calc(100vw - 220px)',
  },
};

export default function Nav() {
  const [navNumber, setNavNumber, urlChecker, checkNavPosition] = useContext(
    GlobalContext
  );
  const url = typeof window !== 'undefined' ? window.location.pathname : '';
  urlChecker(url);

  const [color, setColor] = useState(0);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    checkNavPosition(navNumber);
    function checkToggle() {
      window.innerWidth < 1025 ? setOpen(false) : setOpen(true);
    }

    checkToggle();
    window.addEventListener('resize', checkToggle);
  }, []);

  return (
    <>
      <NavStyles>
        {isOpen && (
          <>
            {links.map((link, i) => (
              <React.Fragment key={i}>
                <motion.li
                  onClick={(e) => {
                    if (e.target.textContent === 'Contact') {
                      e.target.parentNode.parentNode.style.background =
                        '#F45E3F';
                    }
                    setNavNumber(i + 1);
                    checkNavPosition(link.id);
                    setColor('yellow');
                    window.innerWidth < 1025 ? setOpen(!isOpen) : null;
                  }}
                  transition={{
                    type: 'spring',
                    restSpeed: 2,
                    duration: 0.9,
                  }}
                  animate={(function () {
                    if (navNumber > i) {
                      return 'left';
                    }
                    return 'right';
                  })()}
                  whileHover="hover"
                  initial={navNumber && navNumber === 1 ? 'right' : 'left'}
                  variants={variants}
                  id={i}
                  custom={i}
                  style={{
                    background: `${
                      navNumber === i + 1
                        ? 'yellow'
                        : `var(--${link.background})`
                    }`,
                  }}
                >
                  <span>
                    <Link to={link.to}>
                      <div className="text">{link.name}</div>
                    </Link>
                  </span>
                </motion.li>
              </React.Fragment>
            ))}
          </>
        )}
        <div onClick={() => setOpen(!isOpen)}>
          <motion.div className={isOpen ? 'toggle active' : 'toggle'}>
            <span />
            <span />
            <span />
          </motion.div>
        </div>
      </NavStyles>
    </>
  );
}
