import { createGlobalStyle } from 'styled-components';
// import bg from '../assets/images/bg.svg'
const GlobalStyles = createGlobalStyle`
:root {
    --yellow: #EBFF41; // *
    --orange: #DE9D4E; // *
    --sand: #CED1C1; // *
    --lightbeige: #F3EEEC; // *
    --beige: #D8DBCC; // *
    --green: #87C492; // *
    --mushroom: #D8cdc7; //*
    --red: #F45E3F; // *
    --white: #FFFFFF; // *
    --nav-width: 45px; // *
    --grid-width: 870px; // *
    --grid-gap: 20px; // *
    --footer-grid-width: 1140px; // *
    --footer-grid-width: 1140px; // *

    --transitionFade: .25s all ease-in-out; // *

    --flipcardHeight: 200px;
    --flipcardWidth: 200px;

    
    --ContainerMargin: 20px; // **
    --mobileContainerMargin: 20px; // **

    @media screen and (max-width: 39.9375em) {
      --blockpadding: 60px; 
      --footer-gap: 20px; // *
    }

    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      grid-column: span 6;
    }

    // medium
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      --footer-gap: 30px; // *
      --footer-gap: 40px; // *
    }
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      --blockpadding: 90px; 
      --footer-gap: 40px; // *
    }

    // large
    @media screen and (min-width: 64em) {
      --blockpadding: 150px; // *
      --flipcardHeight: 550px;
      --flipcardWidth: 350px;
      --footer-gap: 60px; // *
    }
  }
  html {
    background-size: 700px;
    background-attachment: fixed;
    font-family: 'regular';
  }

  body {
    font-size: 17px;
    line-height: 24px;
    font-weight: normal;
    margin: 0;
    // &.scrolled {
      nav {
          li {
            &:nth-child(1) {
              left: 0;
              z-index: 6;
            }
            &:nth-child(2) {
              left: calc(var(--nav-width));
              z-index: 5;
            }
            &:nth-child(3) {
              left: calc(var(--nav-width) * 2);
              z-index: 4;
            }
            &:nth-child(4) {
              left: calc(var(--nav-width) * 3);
              z-index: 3;
            }
            &:nth-child(5) {
              left: calc(var(--nav-width) * 4);
              z-index: 2;
            }
            &:nth-child(6) {
              left: calc(var(--nav-width) * 5);
              z-index: 1;
            }
          }
      }

    &.dark {
      background: #000;
      color: #FFF;
    }
  }
  ul {
    list-style-type: square;
    li {
      color: inherit;
    }
  }
}
h1,h2,h3,h4,h5,h6 {
  margin: 0 0 5px 0;
}

h1 {
  font-size: 40px;
}
h2 {
  font-size: 25px;
  line-height: 26px;
  @media screen and (min-width: 64em) {
    font-size: 40px;
    line-height: 41px;
  }

}
h3 {
  font-size: 25px;
  line-height: 26px;
  @media screen and (min-width: 64em) {
    font-size: 38px;
    line-height: 42px;
  }
}
h4 {
  font-size: 21px;
  line-height: 33px;
  @media screen and (min-width: 64em) {
    font-size: 28px;
    line-height: 33px;
  }
  font-family: 'medium';
}
h5 {
  font-family: 'regular'!important;
  font-size: 26px;
  font-weight: 300;
  line-height: 30px;
}
h6 {
  font-size: 15px;
  @media screen and (min-width: 64em) {
    font-size: 16px;
  }
  color: var(--red);
}

b ,strong { 
  font-family: 'medium';
  font-weight: normal;
  line-height: 27px;
}

li {
  font-size: 17px;
  line-height: 27px;
}
a {
  color: var(--red);
  font-family: 'medium';
  &:hover {
    text-decoration: none;
  }
}

p {
  margin-bottom: 15px;
}
.grey {
  color: rgba(0,0,0,0.5);
}
.button {
  background: var(--red);
  color: var(--white);
  font-family: 'regular';
  padding: 6px 18px;
  text-decoration: none;
  transition: .3s all ease-in-out;
  cursor: pointer;
  &:hover {
    background: black;
    color: var(--white);
  }
}
}

.text-color-sand {
  color: var(--sand);
}
.text-color-orange {
  color: var(--orange);
}
.text-color-green {
  color: var(--green);
}
.text-color-red {
  color: var(--red);
}

input {
  border: 0;
  margin-top: 10px;
  border-bottom: 2px solid var(--beige);
  padding: 8px 0px;
  background: rgba(255, 255, 255, 0);
  color: black;
}

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  button {
    background: var(--purple);
    color: white;
    border: 0;
    padding: 0.6rem 1rem;
    border-radius: 2px;
    cursor: pointer;
    --cast: 2px;
    box-shadow: var(--cast) var(--cast) 0 var(--grey);
    text-shadow: 0.5px 0.5px 0 rgba(0,0,0,0.2);
    transition: all 0.2s;
    &:hover {
      --cast: 4px;
    }
  }
  .coverFront {f
    transition: 0s all ease-in-out;
  }
  .is-hidden {
    opacity: 0;
    pointer-events: none;
    transition: 1.2s all ease-in-out;
  }
  .container {
    grid-column: span 12;
    margin: var(--blockpadding) 0;
    & +.container {
      
    }
    &.event-holder {
      @media screen and (max-width: 39.9375em) {
        // padding-left: var(--mobileContainerMargin);
        // padding-right: var(--mobileContainerMargin);
        width: calc(100vw - var(--mobileContainerMargin) - var(--mobileContainerMargin));
      }
    }
  }
  .container-no-margin {
    margin:  var(--blockpadding) 0 0;
    grid-column: span 12;
  }
  .container.no-margin-top {
    margin-top: 0;
  }
  .container.no-margin-bottom {
    margin: 0 0 0;
    grid-column: span 12;
  }

  .block {
    @media screen and (max-width: 39.9375em) {
      padding-left: var(--mobileContainerMargin);
      padding-right: var(--mobileContainerMargin);
    }
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      padding-left: var(--mobileContainerMargin);
      padding-right: var(--mobileContainerMargin);
    }
    grid-column: span 12;
    padding: var(--blockpadding) 0;
    max-width: var(--grid-width);
    display: grid;
    grid-gap: var(--grid-gap);
    grid-template-columns: repeat(12, 1fr);
    margin: 0 auto;

    &.no-padding-top {
      padding-top: 0;
    }
    &.no-padding {
      @media screen and (max-width: 39.9375em) {
        padding-left: var(--mobileContainerMargin);
        padding-right: var(--mobileContainerMargin);
      }
      @media screen and (min-width: 40em) and (max-width: 63.9375em) {
        padding-left: var(--mobileContainerMargin);
        padding-right: var(--mobileContainerMargin);
      }
      padding: 0;
    }
    &.text-link {
      min-height: 100vh;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .footer-block {
    max-width: 100%;
    @media screen and (min-width: 64em) {
      max-width: 80%;
    }
    display: grid;
    grid-column: span 12;
    grid-template-columns: repeat(12, 1fr);
    max-width: var(--footer-grid-width);
    grid-gap: var(--grid-gap);
    padding-bottom: 60px;
    padding-top: 60px;
    margin: -15px auto 0;
  }

  .grid {
    grid-column: span 12;
    max-width: var(--grid-width);
    display: grid;
    grid-gap: var(--grid-gap);
    grid-template-columns: repeat(12, 1fr);
    margin: 0 auto;
  }

  .wrapper {
    &.background.beige {
        background: var(--beige);
    }
    &.background.lightbeige {
        background: var(--lightbeige);
    }
  }

  .carousel {
    outline: none;
    @media screen and (max-width: 39.9375em) {
      overflow: hidden;
    }
    grid-column: 1 / span 12;
    margin-bottom: var(--blockpadding);
  }

  .arrow-back {
    transform: rotate(180deg);
    margin-bottom: 60px;
    cursor: pointer;
    @media screen and (max-width: 39.9375em) {
      margin-bottom: 30px;
      margin-top: 30px;
    }
    
    path {
      transition: .2s all ease-in-out;
    }
    path {
      stroke: var(--red);
    }
    &:hover {
      path {
        stroke: var(--mushroom);
      }
    }
  }
  .carousel-slide-arrow {
    @media screen and (min-width: 64em) {
      margin-bottom: 60px;
    }
    cursor: pointer;
    path {
      transition: .2s all ease-in-out;
    }
    path {
      stroke: var(--yellow);
    }
    &:hover {
      path {
        stroke: var(--red);
      }
    }
  }
  
  .event-container {
    @media screen and (max-width: 39.9375em) {
      margin-left: -20px;
      margin-right: -20px;
    }
  }
  .months-holder {
    @media screen and (max-width: 39.9375em) {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 120%;
        height: 30px;
        right: 0;
        overflow: hidden;
        pointer-events: none;
        top: 0;
        background: linear-gradient(-90deg, var(--lightbeige), rgba(255, 255, 255, 0));
      }
    }
  }
  .months {
    @media screen and (max-width: 39.9375em) {
      margin-top: 20px;
      margin-bottom: 20px;
      overflow-x: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      padding-top: 5px;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      overflow: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    overflow: none;
    display: flex;
    min-height: 40px;
    flex-direction: row;
    grid-gap: var(--grid-gap);
    align-items: space-between;
    margin-bottom: 30px;
    .month {
      transition: .15s all ease-in-out;
      flex: 1;
      text-align: center;
      cursor: pointer;
    }
  }
  .month.is-active {
    transform: rotate(-10deg)!important;
    font-family: 'medium'!important;
  }


  .toggle {
    position: fixed;
    z-index: 100;
    right: 20px;
    top: 40px;
    height: 30px;
    width: 30px;
    display: block;
    cursor: pointer;
    span {
      transition: 0.2s all ease-in-out;
      width: 30px;
      height: 3px;
      display: block;
      background: #000;
      &:nth-child(2) {
        transform: translateY(5px);
      }
      &:nth-child(3) {
        transform: translateY(10px);
      }
    }
    &.active {
      span {
        &:nth-child(1) {
          transform: translateY(5px) rotate(-45deg);
        }
        &:nth-child(2) {
          opacity: 0;
          visibility: hidden;
        }
        &:nth-child(3) {
          transform: translateY(0px) rotate(45deg);
        }
      }
    }
  }
  @media screen and (min-width: 64em) {
    .toggle {
      display: none;
    }
  }

  .layer {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
  }
  .pointer {
    cursor: pointer;
  }

  .event-list-style {
    min-height: 100px;
    grid-column: span 12;
    @media screen and (min-width: 64em) {
      grid-column: 6 / span 7;
    }
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      order: 2;
      grid-column: 6 / span 7;
    }
    a {
      text-decoration: none;
      color: black;
    }
    .event {
      &:nth-child(1) {
        border-top: 2px solid #d2c6c0;
      }
      border-bottom: 2px solid #d2c6c0;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .event-list,
    .location {
      font-family: 'regular';
      font-size: 14px;
    }
    .text-holder {
      display: flex;
      .left {
        h5 {
          font-size: 22px;
          margin-bottom: 0;
        }
        flex: 2;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      .right {
        @media screen and (max-width: 39.9375em) {
          flex: 2;
        }
        flex: 1;
        text-align: right;
        justify-content: center;
        flex-direction: column;
        font-family: 'regular';
      }
    }
  }
  .image-style {
    grid-column: span 12;
    @media screen and (max-width: 39.9375em) {
      order: 2;
    }
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      order: 2;
      grid-column: 1 / span 4;
    }
    @media screen and (min-width: 64em) {
      grid-column: 1 / span 4;
    }
  }

    a.event-item-styles {
      h5 {
        transition: 0.3s all ease-out;
      }
      &:hover {
        h5 {
          color: var(--red);
        }
      }
    }
  

`;

export default GlobalStyles;
