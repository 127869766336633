import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { useCookies } from 'react-cookie';
import GlobalStyles from '../styles/GlobalStyles';
import TypographyStyles from '../styles/Typography';
import Nav from './Nav';
import Footer from './Footer';
import CoverFront from './covers/CoverFront';
import { GlobalProvider } from '../context/GlobalContextProvider';

export default function Layout({ data, children }) {
  const [cookies, setCookie] = useCookies(['front']);
  const [isHome, setIsHome] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);

  const [show, setShow] = useState(false);

  useEffect(() => {
    isInitialRender ? setCookie('front', true, { path: '/' }) : null;
    if (isInitialRender) {
      window.location.pathname == '/' ? setIsHome(true) : setIsHome(false);
    }
    setTimeout(() => {
      setShow(true);
    }, 1000);
  }, [isHome, isInitialRender]);

  return (
    <>
      <GlobalProvider>
        <GlobalStyles />
        <TypographyStyles />
        {cookies.front && isHome ? <CoverFront /> : null}
        {show && (
          <>
            <Nav />
            <main
              style={{
                background: 'var(--lightbeige)',
                width: '100%',
                minHeight: '70vh',
                overflow: 'hidden',
              }}
            >
              {children}
            </main>
            <Footer footerData={data.footer} />
          </>
        )}
      </GlobalProvider>
    </>
  );
}
