import React from 'react';
import { motion } from 'framer-motion';

export default function DesktopCoverSVG() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1281.821"
        height="762.168"
        viewBox="0 0 1281.821 762.168"
        style={{ width: '100vw', height: '100vh' }}
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              width="1281.821"
              height="762.168"
              fill="none"
              stroke="rgba(0,0,0,0)"
              strokeWidth="15"
            />
          </clipPath>
        </defs>
        <g
          id="Group_258"
          data-name="Group 258"
          transform="translate(0.91 65.168)"
        >
          <g transform="translate(-0.91 -65.168)">
            <g clipPath="url(#clip-path)">
              <motion.line
                initial={{ transform: `translate(70%, -30%)` }}
                animate={{
                  transform: `translate(90%, 89.5%)`,
                }}
                transition={{
                  type: 'ease-in',
                  duration: 1.3,
                  stiffness: 30,
                }}
                x1="60.739"
                y2="46.806"
                transform="translate(1157.192 695.323)"
                fill="none"
                stroke="#f0eae8"
                strokeMiterlimit="10"
                strokeWidth="15"
              />
              <motion.line
                initial={{ transform: `translate(70%, -30%)` }}
                animate={{
                  transform: `translate(93%, 29.5%)`,
                }}
                transition={{
                  type: 'ease-in',
                  duration: 1,
                  stiffness: 30,
                }}
                x1="60.739"
                y2="46.806"
                transform="translate(1189.64 227.36)"
                fill="none"
                stroke="#f0eae8"
                strokeMiterlimit="10"
                strokeWidth="15"
              />
            </g>
          </g>
          <motion.line
            initial={{ transform: `translate(70%, -30%)` }}
            animate={{
              transform: `translate(70%, 24%)`,
            }}
            transition={{
              type: 'ease-in',
              duration: 1,
              stiffness: 30,
            }}
            x1="60.739"
            y2="46.736"
            transform="translate(918.555 154.166)"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <motion.line
            initial={{ transform: `translate(170%, -30%)` }}
            animate={{
              transform: `translate(86%, 58%)`,
            }}
            transition={{
              type: 'ease-in',
              duration: 1,
              stiffness: 30,
            }}
            x1="60.741"
            y2="46.734"
            transform="translate(1110.036 458.494)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <motion.line
            initial={{ transform: `translate(190%, -30%)` }}
            animate={{
              transform: `translate(72%, 62%)`,
            }}
            transition={{
              type: 'ease-in',
              duration: 1.3,
              stiffness: 30,
            }}
            x1="60.739"
            y2="46.736"
            transform="translate(918.555 475.736)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <motion.line
            initial={{ transform: `translate(190%, -0%)` }}
            animate={{
              transform: `translate(90%, 74%)`,
            }}
            transition={{
              type: 'ease-in',
              duration: 1.8,
              stiffness: 30,
            }}
            x1="21.463"
            y2="16.516"
            transform="translate(1178 575.049)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <motion.line
            initial={{ transform: `translate(20%, -30%)` }}
            animate={{
              transform: `translate(47%, 2%)`,
            }}
            transition={{
              type: 'ease-in',
              duration: 1.8,
              stiffness: 30,
            }}
            x1="21.46"
            y2="16.513"
            transform="translate(605.371 15.303)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <motion.line
            initial={{ transform: `translate(20%, -30%)` }}
            animate={{
              transform: `translate(80%, 12%)`,
            }}
            transition={{
              type: 'ease-in',
              duration: 1.3,
              stiffness: 30,
            }}
            x1="21.46"
            y2="16.516"
            transform="translate(1027.979 93.142)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <g
            id="Group_238"
            data-name="Group 238"
            transform="translate(-0.91 -65.168)"
          >
            <g id="Group_96" data-name="Group 96" clipPath="url(#clip-path)">
              <motion.line
                initial={{ transform: `translate(-220%, -79%)` }}
                animate={{
                  transform: `translate(0%, 79%)`,
                }}
                transition={{
                  type: 'ease-in',
                  duration: 1.8,
                  stiffness: 30,
                }}
                x1="21.463"
                y2="16.54"
                transform="translate(14.871 687.053)"
                fill="none"
                stroke="#f0eae8"
                strokeMiterlimit="10"
                strokeWidth="15"
              />
              <motion.line
                initial={{ transform: `translate(-220%, -79%)` }}
                animate={{
                  transform: `translate(32%, 95%)`,
                }}
                transition={{
                  type: 'ease-in',
                  duration: 1.8,
                  stiffness: 30,
                }}
                x1="21.463"
                y2="16.54"
                transform="translate(414.545 722.487)"
                fill="none"
                stroke="#f0eae8"
                strokeMiterlimit="10"
                strokeWidth="15"
              />
            </g>
          </g>
          <motion.line
            initial={{ transform: `translate(-120%, 179%)` }}
            animate={{
              transform: `translate(49%, 80%)`,
            }}
            transition={{
              type: 'ease-in',
              duration: 1.3,
              stiffness: 30,
            }}
            y1="85.178"
            x2="34.966"
            transform="translate(183.832 39.299)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <motion.line
            initial={{ transform: `translate(-120%, 179%)` }}
            animate={{
              transform: `translate(55%, 65%)`,
            }}
            transition={{
              type: 'ease-in',
              duration: 1.8,
              stiffness: 30,
            }}
            y1="85.178"
            x2="34.964"
            transform="translate(702.549 499.381)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <g transform="translate(-0.91 -65.168)">
            <g id="Group_98" data-name="Group 98" clipPath="url(#clip-path)">
              <motion.line
                initial={{ transform: `translate(-120%, 179%)` }}
                animate={{
                  transform: `translate(10%, 78%)`,
                }}
                transition={{
                  type: 'ease-in',
                  duration: 1.8,
                  stiffness: 30,
                }}
                y1="85.306"
                x2="34.964"
                transform="translate(128.507 661.614)"
                fill="none"
                stroke="#f0eae8"
                strokeMiterlimit="10"
                strokeWidth="15"
              />

              <motion.line
                initial={{ transform: `translate(-120%, 179%)` }}
                animate={{
                  transform: `translate(95%, 3%)`,
                }}
                transition={{
                  type: 'ease-in',
                  duration: 1.8,
                  stiffness: 30,
                }}
                y1="85.306"
                x2="34.966"
                transform="translate(1218.378 21.179)"
                fill="none"
                stroke="#f0eae8"
                strokeMiterlimit="10"
                strokeWidth="15"
              />
            </g>
          </g>
          <motion.line
            initial={{ transform: `translate(-120%, 179%)` }}
            animate={{
              transform: `translate(56%, 10%)`,
            }}
            transition={{
              type: 'ease-in',
              duration: 1.8,
              stiffness: 30,
            }}
            y1="85.178"
            x2="34.964"
            transform="translate(715.764 58.505)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <g transform="translate(-0.91 -65.168)">
            <g id="Group_100" data-name="Group 100" clipPath="url(#clip-path)">
              <motion.path
                initial={{ transform: `translate(-120%, 179%)` }}
                animate={{
                  transform: `translate(6%, 40%)`,
                }}
                transition={{
                  type: 'ease-in',
                  duration: 1.8,
                  stiffness: 30,
                }}
                d="M0,85.308,34.966,0"
                transform="translate(67.119 367.979)"
                fill="none"
                stroke="#f0eae8"
                strokeWidth="15"
              />
            </g>
          </g>
          <motion.line
            initial={{ transform: `translate(-120%, 179%)` }}
            animate={{
              transform: `translate(61%, 23%)`,
            }}
            transition={{
              type: 'ease-in',
              duration: 1.8,
              stiffness: 30,
            }}
            y2="43.925"
            transform="translate(814.429 177.569)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <g transform="translate(-0.91 -65.168)">
            <g id="Group_102" data-name="Group 102" clipPath="url(#clip-path)">
              <motion.line
                initial={{ transform: `translate(-120%, 179%)` }}
                animate={{
                  transform: `translate(3%, 1%)`,
                }}
                transition={{
                  type: 'ease-in',
                  duration: 1.8,
                  stiffness: 30,
                }}
                y2="43.991"
                transform="translate(25.603 15.838)"
                fill="none"
                stroke="#f0eae8"
                strokeMiterlimit="10"
                strokeWidth="15"
              />
              <motion.line
                initial={{ transform: `translate(-120%, -179%)` }}
                animate={{
                  transform: `translate(23.5%, 2%)`,
                }}
                transition={{
                  type: 'ease-in',
                  duration: 1.3,
                  stiffness: 30,
                }}
                y2="43.991"
                transform="translate(300.565 15.838)"
                fill="none"
                stroke="#f0eae8"
                strokeMiterlimit="10"
                strokeWidth="15"
              />
              <motion.line
                initial={{ transform: `translate(-120%, -179%)` }}
                animate={{
                  transform: `translate(28.5%, 0%)`,
                }}
                transition={{
                  type: 'ease-in',
                  duration: 1.8,
                  stiffness: 30,
                }}
                y2="43.991"
                transform="translate(367.089 2.298)"
                fill="none"
                stroke="#f0eae8"
                strokeMiterlimit="10"
                strokeWidth="15"
              />
            </g>
          </g>
          <motion.line
            initial={{ transform: `translate(140%, -109%)` }}
            animate={{
              transform: `translate(13.5%, 8%)`,
            }}
            transition={{
              type: 'ease-in',
              duration: 1.8,
              stiffness: 30,
            }}
            y2="43.925"
            transform="translate(131.365 -25.65)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <motion.path
            initial={{ transform: `translate(200%, -129%)` }}
            animate={{
              transform: `translate(10.3%, 22%)`,
            }}
            transition={{
              type: 'ease-in',
              duration: 1.8,
              stiffness: 30,
            }}
            d="M0,0V43.925"
            transform="translate(131.365 165.505)"
            fill="none"
            stroke="#f0eae8"
            strokeWidth="15"
          />
          <motion.line
            initial={{ transform: `translate(-110%, -129%)` }}
            animate={{
              transform: `translate(64%, 75%)`,
            }}
            transition={{
              type: 'ease-in',
              duration: 1.8,
              stiffness: 30,
            }}
            x1="23.691"
            y2="22.832"
            transform="translate(827.371 531.562)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <g transform="translate(-0.91 -65.168)">
            <g id="Group_104" data-name="Group 104" clipPath="url(#clip-path)">
              <motion.line
                initial={{ transform: `translate(120%, -309%)` }}
                animate={{
                  transform: `translate(76%, 86%)`,
                }}
                transition={{
                  type: 'ease-in',
                  duration: 1.3,
                  stiffness: 30,
                }}
                x1="23.691"
                y2="22.866"
                transform="translate(972.768 721.571)"
                fill="none"
                stroke="#f0eae8"
                strokeMiterlimit="10"
                strokeWidth="15"
              />
            </g>
          </g>
          <motion.line
            initial={{ transform: `translate(220%, -129%)` }}
            animate={{
              transform: `translate(34%, 70%)`,
            }}
            transition={{
              type: 'ease-in',
              duration: 0.7,
              stiffness: 30,
            }}
            x2="23.691"
            y2="22.829"
            transform="translate(427.353 467.902)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <motion.line
            initial={{ transform: `translate(0%, -279%)` }}
            animate={{
              transform: `translate(71%, 0%)`,
            }}
            transition={{
              type: 'ease-in',
              duration: 0.7,
              stiffness: 30,
            }}
            x2="23.663"
            y2="22.315"
            transform="translate(911.242 -3.688)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <motion.line
            initial={{ transform: `translate(100%, -129%)` }}
            animate={{
              transform: `translate(90%, 10%)`,
            }}
            transition={{
              type: 'ease-in',
              duration: 0.7,
              stiffness: 30,
            }}
            y1="22.417"
            x2="23.566"
            transform="translate(1188.365 110.874)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <g transform="translate(-2.181 -36.25)">
            <motion.path
              initial={{ transform: `translate(90%, -179%)` }}
              animate={{
                transform: `translate(3%, 10%)`,
              }}
              transition={{
                type: 'ease-in',
                duration: 0.7,
                stiffness: 30,
              }}
              d="M0,22.417,23.566,0"
              transform="translate(44.355 110.082)"
              fill="none"
              stroke="#f0eae8"
              strokeWidth="15"
            />
          </g>
          <motion.line
            initial={{ transform: `translate(120%, -179%)` }}
            animate={{
              transform: `translate(26%, 10%)`,
            }}
            transition={{
              type: 'ease-in',
              duration: 1.3,
              stiffness: 30,
            }}
            y1="22.417"
            x2="23.566"
            transform="translate(326.614 73.833)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <motion.line
            initial={{ transform: `translate(-120%, 179%)` }}
            animate={{
              transform: `translate(34%, 25%)`,
            }}
            transition={{
              type: 'ease-in',
              duration: 0.7,
              stiffness: 30,
            }}
            y1="22.417"
            x2="23.563"
            transform="translate(440.099 187.491)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <g
            id="Group_244"
            data-name="Group 244"
            transform="translate(-0.91 -65.168)"
          >
            <g clipPath="url(#clip-path)">
              <motion.line
                initial={{ transform: `translate(-120%, 129%)` }}
                animate={{
                  transform: `translate(41%, 7%)`,
                }}
                transition={{
                  type: 'ease-in',
                  duration: 1.8,
                  stiffness: 30,
                }}
                y1="48.587"
                transform="translate(537.05)"
                fill="none"
                stroke="#f0eae8"
                strokeMiterlimit="10"
                strokeWidth="15"
              />
            </g>
          </g>
          <g transform="translate(-0.91 -65.168)">
            <g id="Group_108" data-name="Group 108" clipPath="url(#clip-path)">
              <motion.line
                initial={{ transform: `translate(65%, -129%)` }}
                animate={{
                  transform: `translate(97%, 57%)`,
                }}
                transition={{
                  type: 'ease-in',
                  duration: 1.8,
                  stiffness: 30,
                }}
                y1="48.587"
                transform="translate(1269.105 380.393)"
                fill="none"
                stroke="#f0eae8"
                strokeMiterlimit="10"
                strokeWidth="15"
              />
            </g>
          </g>
          <motion.line
            initial={{ transform: `translate(90%, -129%)` }}
            animate={{
              transform: `translate(85%, 50%)`,
            }}
            transition={{
              type: 'ease-in',
              duration: 1.8,
              stiffness: 30,
            }}
            y1="48.514"
            transform="translate(1093.276 315.263)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <motion.line
            initial={{ transform: `translate(80%, 129%)` }}
            animate={{
              transform: `translate(48%, 75%)`,
            }}
            transition={{
              type: 'ease-in',
              duration: 1.8,
              stiffness: 30,
            }}
            x1="31.904"
            transform="translate(568.619 573.822)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <motion.line
            initial={{ transform: `translate(20%, 129%)` }}
            animate={{
              transform: `translate(45%, 70%)`,
            }}
            transition={{
              type: 'ease-in',
              duration: 1.8,
              stiffness: 30,
            }}
            x1="31.904"
            transform="translate(568.619 479.31)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <motion.line
            initial={{ transform: `translate(10%, 129%)` }}
            animate={{
              transform: `translate(39%, 61%)`,
            }}
            transition={{
              type: 'ease-in',
              duration: 1.8,
              stiffness: 30,
            }}
            x1="31.703"
            transform="translate(835.209 133.316)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <motion.line
            initial={{ transform: `translate(-210%, 94%)` }}
            animate={{
              transform: `translate(45%, 70%)`,
            }}
            transition={{
              type: 'ease-in',
              duration: 1.8,
              stiffness: 30,
            }}
            x1="31.7"
            transform="translate(568.619 157.963)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <g transform="translate(-2.181 -36.25)">
            <g>
              <motion.path
                initial={{ transform: `translate(20%, 229%)` }}
                animate={{
                  transform: `translate(42.2%, 53.3%)`,
                }}
                transition={{
                  type: 'ease-in',
                  duration: 1.8,
                  stiffness: 30,
                }}
                d="M0,0,23.691,22.829"
                transform="translate(541.006 406.551)"
                fill="none"
                stroke="#f0eae8"
                strokeWidth="15"
              />
              <motion.path
                initial={{ transform: `translate(30%, -129%)` }}
                animate={{
                  transform: `translate(47.4%, 42.3%)`,
                }}
                transition={{
                  type: 'ease-in',
                  duration: 1.2,
                  stiffness: 30,
                }}
                d="M0,0,23.663,22.315"
                transform="translate(608.698 322.607)"
                fill="none"
                stroke="#f0eae8"
                strokeWidth="15"
              />
              <motion.path
                initial={{ transform: `translate(-120%, 0%)` }}
                animate={{
                  transform: `translate(42%, 46.2%)`,
                }}
                transition={{
                  type: 'ease-in',
                  duration: 1.8,
                  stiffness: 30,
                }}
                d="M0,48.514V0"
                transform="translate(538.321 351.513)"
                fill="none"
                stroke="#f0eae8"
                strokeWidth="15"
              />
              <motion.path
                initial={{ transform: `translate(-150%, 0%)` }}
                animate={{
                  transform: `translate(49.5%, 46.2%)`,
                }}
                transition={{
                  type: 'ease-in',
                  duration: 1.4,
                  stiffness: 30,
                }}
                d="M0,48.514V0"
                transform="translate(635.144 351.513)"
                fill="none"
                stroke="#f0eae8"
                strokeWidth="15"
              />
              <motion.path
                initial={{ transform: `translate(70%, 180%)` }}
                animate={{ transform: `translate(44.5%, 56.6%)` }}
                transition={{
                  type: 'ease-in',
                  duration: 1,
                  stiffness: 30,
                }}
                d="M31.9,0H0"
                transform="translate(570.8 431.866)"
                fill="none"
                stroke="#f0eae8"
                strokeWidth="15"
              />
              <motion.path
                initial={{ transform: `translate(20%, -180%)` }}
                animate={{ transform: `translate(44.5%, 42.1%)` }}
                transition={{
                  type: 'ease-in',
                  duration: 0.5,
                  stiffness: 30,
                }}
                d="M31.7,0H0"
                transform="translate(570.8 320.165)"
                fill="none"
                stroke="#f0eae8"
                strokeWidth="15"
              />
              <motion.line
                initial={{ transform: `translate(20%, -120%)` }}
                animate={{ transform: `translate(42.2%, 42.4%)` }}
                transition={{
                  type: 'ease-in',
                  duration: 0.9,
                  stiffness: 30,
                }}
                y1="22.417"
                x2="23.566"
                transform="translate(540.954 322.569)"
                fill="none"
                stroke="#f0eae8"
                strokeMiterlimit="10"
                strokeWidth="15"
              />
              <motion.line
                initial={{ transform: `translate(80%, 180%)` }}
                animate={{ transform: `translate(47.4%, 53.4%)` }}
                transition={{
                  type: 'ease-in',
                  duration: 0.9,
                  stiffness: 30,
                }}
                x1="23.694"
                y2="22.829"
                transform="translate(608.764 406.551)"
                fill="none"
                stroke="#f0eae8"
                strokeMiterlimit="10"
                strokeWidth="15"
              />
            </g>
          </g>
          <motion.line
            initial={{ transform: `translate(20%, -120%)` }}
            animate={{ transform: `translate(33%, 4%)` }}
            transition={{
              type: 'ease-in',
              duration: 1.2,
              stiffness: 30,
            }}
            x1="80.783"
            transform="translate(397.145 41.158)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <g transform="translate(-2.181 -36.25)">
            <g id="Group_212" data-name="Group 212">
              <motion.path
                initial={{ transform: `translate(20%, -20%)` }}
                animate={{ transform: `translate(34.5%, 43.6%)` }}
                transition={{
                  type: 'ease-in',
                  duration: 1.8,
                  stiffness: 30,
                }}
                d="M0,85.181,34.964,0"
                transform="translate(442.985 333.128)"
                fill="none"
                stroke="#f0eae8"
                strokeWidth="15"
              />
              <motion.path
                initial={{ transform: `translate(20%, -120%)` }}
                animate={{ transform: `translate(31.5%, 42%)` }}
                transition={{
                  type: 'ease-in',
                  duration: 1.8,
                  stiffness: 30,
                }}
                d="M80.783,0H0"
                transform="translate(399.326 319.653)"
                fill="none"
                stroke="#f0eae8"
                strokeWidth="15"
              />
            </g>
          </g>
          <motion.line
            initial={{ transform: `translate(20%, -120%)` }}
            animate={{ transform: `translate(14%, 74%)` }}
            transition={{
              type: 'ease-in',
              duration: 1.8,
              stiffness: 30,
            }}
            x1="57.677"
            transform="translate(171.418 563.88)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <motion.line
            initial={{ transform: `translate(20%, 120%)` }}
            animate={{ transform: `translate(20%, 63%)` }}
            transition={{
              type: 'ease-in',
              duration: 1.8,
              stiffness: 30,
            }}
            x1="57.677"
            transform="translate(270.817 502.294)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <motion.line
            initial={{ transform: `translate(-220%, 120%)` }}
            animate={{ transform: `translate(83%, -1%)` }}
            transition={{
              type: 'ease-in',
              duration: 1.8,
              stiffness: 30,
            }}
            x1="57.677"
            transform="translate(1081.198 -1.431)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <g transform="translate(-0.91 -65.168)">
            <g id="Group_110" clipPath="url(#clip-path)">
              <g id="Group_200">
                <motion.line
                  initial={{ transform: `translate(-220%, 120%)` }}
                  animate={{ transform: `translate(1.5%, 34%)` }}
                  transition={{
                    type: 'ease-in',
                    duration: 1.8,
                    stiffness: 30,
                  }}
                  x1="57.677"
                  transform="translate(26.029 237.127)"
                  fill="none"
                  stroke="#f0eae8"
                  strokeMiterlimit="10"
                  strokeWidth="15"
                />
              </g>
            </g>
          </g>
          <motion.line
            initial={{ transform: `translate(-220%, 120%)` }}
            animate={{ transform: `translate(20.5%, 31%)` }}
            transition={{
              type: 'ease-in',
              duration: 1.8,
              stiffness: 30,
            }}
            x1="57.677"
            transform="translate(270.817 171.959)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <g
            id="Group_249"
            data-name="Group 249"
            transform="translate(-0.91 -65.168)"
          >
            <g id="Group_112" data-name="Group 112" clipPath="url(#clip-path)">
              <motion.line
                initial={{ transform: `translate(-220%, 120%)` }}
                animate={{ transform: `translate(26%, 78%)` }}
                transition={{
                  type: 'ease-in',
                  duration: 1.8,
                  stiffness: 30,
                }}
                y2="130.001"
                transform="translate(367.089 603.749)"
                fill="none"
                stroke="#f0eae8"
                strokeMiterlimit="10"
                strokeWidth="15"
              />
            </g>
          </g>
          <g transform="translate(-2.181 -36.25)">
            <g>
              <g>
                <g>
                  <g>
                    <motion.line
                      initial={{ transform: `translate(10%, -120%)` }}
                      animate={{ transform: `translate(28.75%, 32.1%)` }}
                      transition={{
                        type: 'ease-in',
                        duration: 1.8,
                        stiffness: 30,
                      }}
                      y2="43.925"
                      transform="translate(368.359 235.816)"
                      fill="none"
                      stroke="#f0eae8"
                      strokeMiterlimit="10"
                      strokeWidth="15"
                    />
                    <motion.path
                      initial={{ transform: `translate(30%, -180%)` }}
                      animate={{ transform: `translate(28.75%, 41.1%)` }}
                      transition={{
                        type: 'ease-in',
                        duration: 1.8,
                        stiffness: 30,
                      }}
                      d="M0,0V129.807"
                      transform="translate(368.359 311.193)"
                      fill="none"
                      stroke="#f0eae8"
                      strokeWidth="15"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <motion.line
            initial={{ transform: `translate(30%, -180%)` }}
            animate={{ transform: `translate(88.75%, 32.1%)` }}
            transition={{
              type: 'ease-in',
              duration: 1.8,
              stiffness: 30,
            }}
            x1="38.824"
            y2="109.289"
            transform="translate(1161.323 292.816)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <motion.line
            initial={{ transform: `translate(30%, -180%)` }}
            animate={{ transform: `translate(83.75%, 17.1%)` }}
            transition={{
              type: 'ease-in',
              duration: 1.8,
              stiffness: 30,
            }}
            x1="38.582"
            y2="108.484"
            transform="translate(1073.985 131.321)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <motion.line
            initial={{ transform: `translate(30%, -180%)` }}
            animate={{ transform: `translate(68.75%, 73.1%)` }}
            transition={{
              type: 'ease-in',
              duration: 1.8,
              stiffness: 30,
            }}
            x1="38.585"
            y2="108.484"
            transform="translate(874.983 549.479)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <g transform="translate(-0.91 -65.168)">
            <g id="Group_114" data-name="Group 114" clipPath="url(#clip-path)">
              <motion.line
                initial={{ transform: `translate(190%, 180%)` }}
                animate={{ transform: `translate(64%, 90%)` }}
                transition={{
                  type: 'ease-in',
                  duration: 1.8,
                  stiffness: 30,
                }}
                x1="16.935"
                y2="45.205"
                transform="translate(826.903 700.541)"
                fill="none"
                stroke="#f0eae8"
                strokeMiterlimit="10"
                strokeWidth="15"
              />
              <motion.path
                initial={{ transform: `translate(190%, 180%)` }}
                animate={{ transform: `translate(40%, 83%)` }}
                transition={{
                  type: 'ease-in',
                  duration: 1.8,
                  stiffness: 30,
                }}
                d="M43.859,0,0,123.441"
                transform="translate(502.321 627.967)"
                fill="none"
                stroke="#f0eae8"
                strokeWidth="15"
              />
              <motion.line
                initial={{ transform: `translate(190%, 180%)` }}
                animate={{ transform: `translate(63%, 1%)` }}
                transition={{
                  type: 'ease-in',
                  duration: 1.8,
                  stiffness: 30,
                }}
                x1="43.859"
                y2="123.441"
                transform="translate(799.979 11.026)"
                fill="none"
                stroke="#f0eae8"
                strokeMiterlimit="10"
                strokeWidth="15"
              />
            </g>
          </g>
          <g transform="translate(-2.181 -36.25)">
            <motion.path
              initial={{ transform: `translate(-100%, -180%)` }}
              animate={{ transform: `translate(72%, 41.5%)` }}
              transition={{
                type: 'ease-in',
                duration: 1.8,
                stiffness: 30,
              }}
              d="M60.739,0,0,46.736"
              transform="translate(920.736 317)"
              fill="none"
              stroke="#f0eae8"
              strokeWidth="15"
            />
            <motion.path
              initial={{ transform: `translate(10%, 120%)` }}
              animate={{ transform: `translate(75.8%, 54.8%)` }}
              transition={{
                type: 'ease-in',
                duration: 1.8,
                stiffness: 30,
              }}
              d="M21.463,0,0,16.513"
              transform="translate(972.575 417.259)"
              fill="none"
              stroke="#f0eae8"
              strokeWidth="15"
            />
            <motion.line
              initial={{ transform: `translate(40%, 180%)` }}
              animate={{ transform: `translate(74.5%, 43%)` }}
              transition={{
                type: 'ease-in',
                duration: 1.8,
                stiffness: 30,
              }}
              x1="38.824"
              y2="109.289"
              transform="translate(953.501 329.066)"
              fill="none"
              stroke="#f0eae8"
              strokeMiterlimit="10"
              strokeWidth="15"
            />
            <motion.line
              initial={{ transform: `translate(80%, -110%)` }}
              animate={{ transform: `translate(68.7%, 43%)` }}
              transition={{
                type: 'ease-in',
                duration: 1.8,
                stiffness: 30,
              }}
              x1="38.585"
              y2="108.484"
              transform="translate(877.164 329.872)"
              fill="none"
              stroke="#f0eae8"
              strokeMiterlimit="10"
              strokeWidth="15"
            />
            <motion.path
              initial={{ transform: `translate(89%, -210%)` }}
              animate={{ transform: `translate(62.3%, 42%)` }}
              transition={{
                type: 'ease-in',
                duration: 1.8,
                stiffness: 30,
              }}
              d="M43.859,0,0,123.256"
              transform="translate(801.249 315.087)"
              fill="none"
              stroke="#f0eae8"
              strokeWidth="15"
            />
            <motion.line
              initial={{ transform: `translate(-140%, -10%)` }}
              animate={{ transform: `translate(62.8%, 41.6%)` }}
              transition={{
                type: 'ease-in',
                duration: 1.8,
                stiffness: 30,
              }}
              x1="21.463"
              y2="16.513"
              transform="translate(805.877 316.977)"
              fill="none"
              stroke="#f0eae8"
              strokeMiterlimit="10"
              strokeWidth="15"
            />
            <motion.line
              initial={{ transform: `translate(-140%, -10%)` }}
              animate={{ transform: `translate(66.0%, 41.6%)` }}
              transition={{
                type: 'ease-in',
                duration: 1.8,
                stiffness: 30,
              }}
              x1="60.739"
              y2="46.736"
              transform="translate(845.108 317)"
              fill="none"
              stroke="#f0eae8"
              strokeMiterlimit="10"
              strokeWidth="15"
            />
          </g>
          <motion.line
            initial={{ transform: `translate(-140%, -10%)` }}
            animate={{ transform: `translate(50%, 26%)` }}
            transition={{
              type: 'ease-in',
              duration: 1.8,
              stiffness: 30,
            }}
            x1="72.954"
            transform="translate(682.327 195.002)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <g transform="translate(-0.91 -65.168)">
            <g clipPath="url(#clip-path)">
              <motion.line
                initial={{ transform: `translate(-140%, -10%)` }}
                animate={{ transform: `translate(98%, 75%)` }}
                transition={{
                  type: 'ease-in',
                  duration: 1.8,
                  stiffness: 30,
                }}
                y2="93.937"
                transform="translate(1250.379 572.71)"
                fill="none"
                stroke="#f0eae8"
                strokeMiterlimit="10"
                strokeWidth="15"
              />
            </g>
          </g>

          <motion.line
            initial={{ transform: `translate(-140%, -50%)` }}
            animate={{ transform: `translate(83%, 72%)` }}
            transition={{
              type: 'ease-in',
              duration: 1.8,
              stiffness: 30,
            }}
            y2="93.796"
            transform="translate(1038.437 507.826)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <motion.line
            initial={{ transform: `translate(140%, -50%)` }}
            animate={{ transform: `translate(42.75%, 17.1%)` }}
            transition={{
              type: 'ease-in',
              duration: 1.8,
              stiffness: 30,
            }}
            y2="93.796"
            transform="translate(530.762 149.245)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <g transform="translate(-0.91 -65.168)">
            <g id="Group_118" data-name="Group 118" clipPath="url(#clip-path)">
              <motion.line
                initial={{ transform: `translate(140%, -50%)` }}
                animate={{ transform: `translate(52.5%, 2%)` }}
                transition={{
                  type: 'ease-in',
                  duration: 1.8,
                  stiffness: 30,
                }}
                y2="93.939"
                transform="translate(674.487 6.11)"
                fill="none"
                stroke="#f0eae8"
                strokeMiterlimit="10"
                strokeWidth="15"
              />
            </g>
          </g>
          <g transform="translate(-2.181 -36.25)">
            <motion.path
              initial={{ transform: `translate(120%, -20%)` }}
              animate={{ transform: `translate(53.42%, 42.1%)` }}
              transition={{
                type: 'ease-in',
                duration: 1.2,
                stiffness: 30,
              }}
              d="M72.954,0H0"
              transform="translate(684.508 320.165)"
              fill="none"
              stroke="#f0eae8"
              strokeWidth="15"
            />
            <motion.path
              initial={{ transform: `translate(120%, -30%)` }}
              animate={{ transform: `translate(59.7%, 43.2%)` }}
              transition={{
                type: 'ease-in',
                duration: 1.8,
                stiffness: 30,
              }}
              d="M0,0V93.8"
              transform="translate(766.35 329.051)"
              fill="none"
              stroke="#f0eae8"
              strokeWidth="15"
            />
            <motion.line
              initial={{ transform: `translate(190%, 80%)` }}
              animate={{ transform: `translate(53.42%, 56.4%)` }}
              transition={{
                type: 'ease-in',
                duration: 1.5,
                stiffness: 30,
              }}
              x1="72.954"
              transform="translate(684.508 431.866)"
              fill="none"
              stroke="#f0eae8"
              strokeMiterlimit="10"
              strokeWidth="15"
            />
            <motion.line
              initial={{ transform: `translate(-190%, -90%)` }}
              animate={{ transform: `translate(52.8%, 43.2%)` }}
              transition={{
                type: 'ease-in',
                duration: 1.5,
                stiffness: 30,
              }}
              y2="93.796"
              transform="translate(675.757 329.051)"
              fill="none"
              stroke="#f0eae8"
              strokeMiterlimit="10"
              strokeWidth="15"
            />
          </g>
          <motion.line
            initial={{ transform: `translate(-190%, -10%)` }}
            animate={{ transform: `translate(10.8%, 42.2%)` }}
            transition={{
              type: 'ease-in',
              duration: 1.5,
              stiffness: 30,
            }}
            y2="111.946"
            transform="translate(131.365 292.819)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <g transform="translate(-0.91 -65.168)">
            <g id="Group_120" data-name="Group 120" clipPath="url(#clip-path)">
              <motion.line
                initial={{ transform: `translate(-190%, -10%)` }}
                animate={{ transform: `translate(3.8%, 96.2%)` }}
                transition={{
                  type: 'ease-in',
                  duration: 1.5,
                  stiffness: 30,
                }}
                y2="28.418"
                transform="translate(82.937 733.75)"
                fill="none"
                stroke="#f0eae8"
                strokeMiterlimit="10"
                strokeWidth="15"
              />
              <motion.line
                initial={{ transform: `translate(-190%, -10%)` }}
                animate={{ transform: `translate(58.8%, 91.2%)` }}
                transition={{
                  type: 'ease-in',
                  duration: 1.5,
                  stiffness: 30,
                }}
                y2="71.306"
                transform="translate(746.726 690.862)"
                fill="none"
                stroke="#f0eae8"
                strokeMiterlimit="10"
                strokeWidth="15"
              />
              <motion.line
                initial={{ transform: `translate(190%, -120%)` }}
                animate={{ transform: `translate(17.8%, 91.2%)` }}
                transition={{
                  type: 'ease-in',
                  duration: 1.2,
                  stiffness: 30,
                }}
                y2="71.306"
                transform="translate(262.944 690.862)"
                fill="none"
                stroke="#f0eae8"
                strokeMiterlimit="10"
                strokeWidth="15"
              />
            </g>
          </g>
          <motion.line
            initial={{ transform: `translate(190%, -120%)` }}
            animate={{ transform: `translate(5.8%, 58.2%)` }}
            transition={{
              type: 'ease-in',
              duration: 1.2,
              stiffness: 30,
            }}
            y2="111.946"
            transform="translate(78.789 462.338)"
            fill="none"
            stroke="#f0eae8"
            strokeMiterlimit="10"
            strokeWidth="15"
          />
          <g transform="translate(-2.181 -36.25)">
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <motion.path
                        initial={{ transform: `translate(-120%, 0%)` }}
                        animate={{ transform: `translate(20.7%, 43.1%)` }}
                        transition={{
                          type: 'ease-in',
                          duration: 0.9,
                          stiffness: 30,
                        }}
                        d="M0,0V111.946"
                        transform="translate(264.214 329.068)"
                        fill="none"
                        stroke="#f0eae8"
                        strokeWidth="15"
                      />
                    </g>
                    <g id="Group_218" data-name="Group 218">
                      <motion.path
                        initial={{ transform: `translate(-190%, -30%)` }}
                        animate={{ transform: `translate(21.3%, 42.1%)` }}
                        transition={{
                          type: 'ease-in',
                          duration: 1.8,
                          stiffness: 30,
                        }}
                        d="M57.677,0H0"
                        transform="translate(272.998 320.165)"
                        fill="none"
                        stroke="#f0eae8"
                        strokeWidth="15"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <motion.path
            initial={{ transform: `translate(70%, 120%)` }}
            animate={{ transform: `translate(33.3%, 51.7%)` }}
            transition={{
              type: 'ease-in',
              duration: 1.2,
              stiffness: 30,
            }}
            d="M80.783,0H0"
            transform="translate(438.959 395.469)"
            fill="none"
            stroke="#f0eae8"
            strokeWidth="15"
          />
        </g>
      </svg>
    </div>
  );
}
